import React from "react";
import { AppBar, Toolbar, Typography, Button, TextField, Grid2, Card, CardContent, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

// FitCheck Home Page Component
const FitCheckPage = () => {
  const navigate = useNavigate();

  // Navigate function for the "Get Started" button
  const handleGetStarted = () => {
    navigate('/page2N'); // Modify this to your desired route
  };

  return (
    <Box sx={{ padding: '20px' }}>
      {/* Header */}
      <AppBar position="static" color="transparent" elevation={0} sx={{ mb: 4 }}>
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            FitCheck
          </Typography>
          <TextField
            variant="outlined"
            size="small"
            placeholder="Search products..."
            sx={{ marginRight: '20px', width: '300px' }}
          />
          <Typography variant="body1" sx={{ marginRight: '15px' }}>
            506 Points
          </Typography>
          <Button variant="contained" color="success">Sign In</Button>
        </Toolbar>
      </AppBar>

      {/* Banner Section */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '300px',
          backgroundColor: '#f4efe8',
          textAlign: 'center',
          padding: '20px',
          mb: 4,
        }}
      >
        <Typography variant="h4" component="h1" fontWeight="bold" sx={{ mb: 2 }}>
          Personalized skincare recommendations you can trust.
        </Typography>
        
        <Button variant="contained" color="success" size="large" onClick={handleGetStarted}>
          Get Started
        </Button>
      </Box>

      {/* Steps Section */}
      <Grid2 container spacing={3} justifyContent="center">
        {steps.map((step, index) => (
          <Grid2 item xs={12} sm={6} md={2.4} key={index}>
            <Card sx={{ textAlign: 'center', padding: '10px', backgroundColor: "#fcf9f5" }}>
              <CardContent>
                <Typography variant="h6">{step.title}</Typography>
                <Typography variant="body2" color="textSecondary">
                  {step.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid2>
        ))}
      </Grid2>
    </Box>
  );
};

// Steps data
const steps = [
  { title: 'Step 1', description: 'Tell us about your skin' },
  { title: 'Step 2', description: 'Discover your FitScore matches' },
  { title: 'Step 3', description: 'Earn points on purchases' },
  { title: 'Step 4', description: 'Review products' },
  { title: 'Step 5', description: 'Redeem rewards' },
];

export default FitCheckPage;
