import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, Button, Card, CardContent, Grid, Chip } from '@mui/material';
import data from './data'; // Assuming the product data is in a separate file
import { useNavigate } from 'react-router-dom';

const ProductDetails = () => {
  const { productId } = useParams(); // Get product ID from URL
  const product = data.recommendations.user1.products.find((p) => p.product_id === productId);
  const navigate = useNavigate();
  const handleNext = () => {
    navigate("/PageReview")
  }
  if (!product) {
    return <Typography variant="h6">Product not found</Typography>;
  }

  return (
    <Box sx={{ p: 4, backgroundColor: '#f4efe8' /* Beige background */, minHeight: '100vh' }}>
      <Card
        sx={{
          maxWidth: '1000px',
          margin: 'auto',
          borderRadius: 2,
          backgroundColor: '#fff', // White card background
          p: 3,
        }}
      >
        <Grid container spacing={2}>
          {/* Left Section - Product Image */}
          <Grid item xs={12} md={4}>
            <Box sx={{ p: 2 }}>
              <img
                src={product.image_url}
                alt={product.name}
                style={{ width: '100%', borderRadius: '8px' }}
              />
            </Box>
          </Grid>

          {/* Right Section - Product Info */}
          <Grid item xs={12} md={8}>
            <CardContent sx={{ p: 2 }}>
              {/* Product Name */}
              <Typography variant="body2" color="textSecondary" gutterBottom>
                {product.brand}
              </Typography>
              <Typography variant="h5" fontWeight="bold" gutterBottom>
                {product.name}
              </Typography>

              <Button
                  sx={{
                    backgroundColor: 'teal',
                    color: 'white',
                    mt: 1
                  }}
                  >
                  {`${product.fitscore}% fitscore`}
              </Button>

              <Typography variant="body2" sx={{ fontSize: '0.95rem', color: '#555' , mt: 1}}>
                  Why this fits you: {product.description}
              </Typography>
              

              {/* Key Ingredients */}
              <Typography variant="body1" fontWeight="bold" sx={{ mt: 2 }}>
                Key Ingredients:
              </Typography>
              <Typography variant="body2" sx={{ mt: 1, mb: 2, color: '#777' }}>
               {product.keyingredients.join(', ')}
              </Typography>

              {/* Affiliate Links */}
              <Grid container spacing={2}>
                {product.affiliate_urls.map((affiliate, index) => (
                  <Grid item xs={12} sm={4} key={index}>
                    <Box
                      sx={{
                        textAlign: 'center',
                        padding: 2,
                        border: '1px solid #ddd',
                        borderRadius: '8px',
                        backgroundColor: '#fff',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                      }}
                    >
                      {/* Price */}
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: 'bold',
                          color: '#333',
                          mb: 1,
                        }}
                      >
                        ${affiliate.price}
                      </Typography>

                      {/* Points */}
                      <Typography
                        variant="body2"
                        sx={{
                          display: 'inline-block',
                          backgroundColor: '#ffe6e6',
                          color: '#ff6f61',
                          fontWeight: 'bold',
                          fontSize: '0.85rem',
                          padding: '4px 8px',
                          borderRadius: '16px',
                          marginBottom: '8px',
                        }}
                      >
                        Earn {affiliate.points} Points
                      </Typography>

                      {/* Shop Button */}
                      <Button
                        variant="contained"
                        fullWidth
                        sx={{
                          backgroundColor:
                            affiliate.store === 'Sephora'
                              ? '#000'
                              : affiliate.store === 'Ulta'
                              ? '#e91e63'
                              : '#4caf50', // Green for The Ordinary
                          color: 'white',
                          fontWeight: 'bold',
                          textTransform: 'none',
                          fontSize: '0.7rem', // Smaller font size
                          borderRadius: '8px',
                          padding: '8px 12px', // Adjust padding for smaller text
                          '&:hover': {
                            backgroundColor:
                              affiliate.store === 'Sephora'
                                ? '#333'
                                : affiliate.store === 'Ulta'
                                ? '#d81b60'
                                : '#388e3c',
                          },
                        }}
                        onClick={() => window.open(affiliate.url, '_blank')}
                      >
                        Shop at {affiliate.store}
                      </Button>
                    </Box>
                  </Grid>
                ))}
              </Grid>

              {/* Reviews Section */}
              <Typography variant="body1" fontWeight="bold" sx={{ mt: 3 }}>
                Reviews:
              </Typography>
              {product.reviews.map((review, index) => (
                <Box key={index} sx={{ mt: 2 }}>
                  <Typography variant="body2" sx={{ color: '#555' }}>
                    <strong>{review.name}</strong>: {review.review}
                  </Typography>
                </Box>
              ))}

              {/* Experience Section */}
              <Box sx={{ mt: 4, textAlign: 'center' }}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: '#f4efe8', // Beige button color
                    color: '#333',
                    fontWeight: 'bold',
                    textTransform: 'none',
                    fontSize: '0.9rem',
                    borderRadius: '20px',
                    padding: '10px 20px',
                    '&:hover': {
                      backgroundColor: '#f4efe8', // Darker beige on hover
                    },
                  }}
                  onClick={() => handleNext()}
                >
                  Already bought this? Share your experience and earn 50 points!
                </Button>
              </Box>
            </CardContent>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};

export default ProductDetails;
