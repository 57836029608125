import React, { useState } from 'react';
import { Box, Typography, Button, LinearProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Page2 = () => {
  // State to track the current step
  const [step, setStep] = useState(0);
  const [skinType, setSkinType] = useState('');
  const [concern, setConcern] = useState([]);
  const [goal, setGoal] = useState('');
  const [budget, setBudget] = useState('');

  // Define the total number of steps (for the progress bar calculation)
  const totalSteps = 4;
  const navigate = useNavigate();
  // Function to handle step change (next/previous)
  const handleNext = () => {
    if (step === totalSteps - 1) {
      // Navigate to the next page when on the last step
      navigate('/Page3N'); // Replace '/next-page' with the actual route for the next page
    } else {
      // Proceed to the next step
      setStep((prevStep) => prevStep + 1);
    }
  };
  const handlePrev = () => setStep((prev) => Math.max(prev - 1, 0));

  // Function to calculate progress percentage
  const getProgress = () => ((step + 1) / totalSteps) * 100;

  // Function to handle skin type selection
  const handleSkinTypeSelect = (type) => setSkinType(type);

  // Function to handle concern selection
  const handleConcernSelect = (selectedConcern) => {
    setConcern((prevConcerns) => {
      if (prevConcerns.includes(selectedConcern)) {
        return prevConcerns.filter((concern) => concern !== selectedConcern);
      } else {
        return [...prevConcerns, selectedConcern];
      }
    });
  };

  // Function to handle goal selection
  const handleGoalSelect = (selectedGoal) => setGoal(selectedGoal);

  // Function to handle budget selection
  const handleBudgetSelect = (selectedBudget) => setBudget(selectedBudget);


  // Render the question based on the current step using switch
  const renderQuestion = () => {
    switch (step) {
      case 0:
        return (
          <>
            <Typography variant="h5" gutterBottom textAlign="center">
              What’s your skin type?
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: 2,
                mt: 2,
                backgroundColor: '#ffffff',
                padding: 2,
                borderRadius: 2,
                boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
              }}
            >
              {['Normal', 'Dry', 'Oily', 'Combination'].map((type) => (
                <Button
                  key={type}
                  variant={skinType === type ? 'contained' : 'outlined'}
                  onClick={() => handleSkinTypeSelect(type)}
                  sx={{
                    flex: 1,
                    height: '70px',
                    borderRadius: '12px',
                    fontSize: '16px',
                    fontWeight: '500',
                    color: skinType === type ? '#ffffff' : '#6B8E23',
                    backgroundColor: skinType === type ? '#6B8E23' : '#ffffff',
                    borderColor: '#6B8E23',
                    transition: 'background-color 0.3s, transform 0.3s',
                    '&:hover': {
                      backgroundColor: skinType === type ? '#6B8E23' : 'rgba(107, 142, 35, 0.1)',
                      transform: 'scale(1.05)',
                    },
                  }}
                >
                  {type}
                </Button>
              ))}
            </Box>
          </>
        );
      case 1:
        return (
          <>
            <Typography variant="h5" gutterBottom textAlign="center">
              What are your concerns?
            </Typography>
            <Typography variant="body2" gutterBottom textAlign="center">
              Select all that apply to help us find the perfect products for you.
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: 2,
                mt: 2,
                backgroundColor: '#ffffff',
                padding: 2,
                borderRadius: 2,
                boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
              }}
            >
              {['Acne', 'Dryness', 'Fine Lines', 'Dark Spots', 'Redness', 'Large Pores'].map((concernOption) => (
                <Button
                  key={concernOption}
                  variant={concern.includes(concernOption) ? 'contained' : 'outlined'}
                  onClick={() => handleConcernSelect(concernOption)}
                  sx={{
                    flex: '1 1 calc(50% - 8px)',
                    minHeight: '70px',
                    borderRadius: '12px',
                    fontSize: '16px',
                    fontWeight: '500',
                    color: concern.includes(concernOption) ? '#ffffff' : '#6B8E23',
                    backgroundColor: concern.includes(concernOption) ? '#6B8E23' : '#ffffff',
                    borderColor: '#6B8E23',
                    transition: 'background-color 0.3s, transform 0.3s',
                    '&:hover': {
                      backgroundColor: concern.includes(concernOption) ? '#6B8E23' : 'rgba(107, 142, 35, 0.1)',
                      transform: 'scale(1.05)',
                    },
                  }}
                >
                  {concernOption}
                </Button>
              ))}
            </Box>
          </>
        );
      case 2:
        return (
          <>
            <Typography variant="h5" gutterBottom textAlign="center">
              Set Your Goals
            </Typography>
            <Typography variant="body2" gutterBottom textAlign="center">
              What's most important to you in your skincare journey?
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                mt: 2,
                backgroundColor: '#ffffff',
                padding: 2,
                borderRadius: 2,
                boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
              }}
            >
              {[
                'Prevention First',
                'Active Treatment',
                'Balance & Maintain',
                'Gentle Progress',
              ].map((goalOption) => (
                <Button
                  key={goalOption}
                  variant={goal === goalOption ? 'contained' : 'outlined'}
                  onClick={() => handleGoalSelect(goalOption)}
                  sx={{
                    height: '70px',
                    borderRadius: '12px',
                    fontSize: '16px',
                    fontWeight: '500',
                    color: goal === goalOption ? '#ffffff' : '#6B8E23',
                    backgroundColor: goal === goalOption ? '#6B8E23' : '#ffffff',
                    borderColor: '#6B8E23',
                    transition: 'background-color 0.3s, transform 0.3s',
                    '&:hover': {
                      backgroundColor: goal === goalOption ? '#6B8E23' : 'rgba(107, 142, 35, 0.1)',
                      transform: 'scale(1.05)',
                    },
                  }}
                >
                  {goalOption}
                </Button>
              ))}
            </Box>
          </>
        );
        case 3:
          return (
            <>
              <Typography variant="h5" gutterBottom textAlign="center">
                What’s your budget?
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                  mt: 2,
                  backgroundColor: '#ffffff',
                  padding: 2,
                  borderRadius: 2,
                  boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
                }}
              >
                {['$5-20', '$20-50', '$50+'].map((budgetOption) => (
                  <Button
                    key={budgetOption}
                    variant={budget === budgetOption ? 'contained' : 'outlined'}
                    onClick={() => handleBudgetSelect(budgetOption)}
                    sx={{
                      flex: 1,
                      height: '70px',
                      borderRadius: '12px',
                      fontSize: '16px',
                      fontWeight: '500',
                      color: budget === budgetOption ? '#ffffff' : '#6B8E23',
                      backgroundColor: budget === budgetOption ? '#6B8E23' : '#ffffff',
                      borderColor: '#6B8E23',
                      transition: 'background-color 0.3s, transform 0.3s',
                      '&:hover': {
                        backgroundColor: budget === budgetOption ? '#6B8E23' : 'rgba(107, 142, 35, 0.1)',
                        transform: 'scale(1.05)',
                      },
                    }}
                  >
                    {budgetOption}
                  </Button>
                ))}
              </Box>
            </>
          );

      default:
        return null;
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        padding: 2,
        backgroundColor: '#f1e5d7',
      }}
    >
      <Box
        sx={{
          width: '100%',
          maxWidth: '600px',
        }}
      >
        {/* Progress Bar */}
        <Box sx={{ width: '100%', mb: 3 }}>
          <LinearProgress
            variant="determinate"
            value={getProgress()}
            sx={{
              height: 6,
              borderRadius: 3,
              '& .MuiLinearProgress-bar': { borderRadius: 3 },
            }}
          />
        </Box>

        {/* Current Question */}
        <Box sx={{ mb: 4 }}>{renderQuestion()}</Box>

        {/* Navigation Buttons */}
        <Box sx={{ display: 'flex', justifyContent: step === 0 ? 'flex-end' : 'space-between' }}>
          {step > 0 && (
            <Button variant="outlined" onClick={handlePrev} sx={{ borderRadius: 2, paddingX: 2 }}>
              Previous
            </Button>
          )}
          <Button
            variant="contained"
            onClick={handleNext}
            disabled={(step === 0 && !skinType) || (step === 1 && concern.length === 0) || (step === 2 && !goal)}
            sx={{
              borderRadius: 2,
              paddingX: 2,
              backgroundColor: '#4CAF50',
              color: '#fff',
              '&:hover': {
                backgroundColor: '#388E3C',
              },
            }}
          >
            {step === totalSteps - 1 ? 'Finish' : 'Continue'}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Page2;
