const data = {
    "recommendations": {
      "user1": {
        "products": [
          {
            "product_id": "product1_identifier",
            "rank": 1,
            "name": "Niacinamide 10% Brightening Serum",
            "brand": "The Ordinary",
            "image_url": "https://www.sephora.com/productimages/sku/s2031391-main-zoom.jpg?imwidth=1224",
            "price": "20.00",
            "fitscore": "92",
            "keyingredients": ["Niacinamide (Vitamin B3)", "Zinc PCA", "Tamarindus Indica Seed Gum", "Pentylene Glycol", "Acacia Senegal Gum"] ,
            "affiliate_urls": [
              { "store": "The Ordinary", "price": "20.00", "points": 90, "url": "https://ulta.com/product3" },
              { "store": "Sephora", "price": "20.00", "points": 60, "url": "https://sephora.com/product3" },
              { "store": "Ulta", "price": "20.00", "points": 40, "url": "https://store.com/product3" }
            ],
            "tags": ["hydrating", "organic", "skincare"],
            "description": "A highly effective moisturizer designed for dry skin.",
            "reviews": [
              {
                "id": 1,
                "name": "John Doe",
                "review": "This moisturizer works wonders for dry skin."
              }
            ]
          },
          {
            "product_id": "product2_identifier",
            "rank": 2,
            "name": "Niacinamide 10% Solution",
            "brand": "Paula\'s Choice",
            "image_url": "https://www.sephora.com/productimages/sku/s2549343-main-zoom.jpg?imwidth=1224",
            "keyingredients": ["Niacinamide (Vitamin B3)", "Licorice Root Extract", "Vitamin C (Ascorbyl Glucoside)", "Willow Bark Extract", "Panthenol (Pro-Vitamin B5)"],
            "price": "44.00",
            "fitscore": "88",
            "affiliate_urls": [
              { "store": "Paula's Choice", "price": "44.00", "points": 90, "url": "https://store.com/product3" },
              { "store": "Sephora", "price": "44.00", "points": 60, "url": "https://sephora.com/product3" },
              { "store": "Ulta", "price": "44.00", "points": 40, "url": "https://ulta.com/product3" },
            ],
            "tags": ["soothing", "natural", "skincare"],
            "description": "A calming moisturizer perfect for sensitive skin.",
            "reviews": [
              {
                "id": 2,
                "name": "Jane Doe",
                "review": "This product is perfect for my sensitive skin!"
              }
            ]
          },
          {
            "product_id": "product3_identifier",
            "rank": 3,
            "name": "Ultra Facial Hydrating Cream",
            "brand" : "Kiehl's",
            "image_url": "https://www.sephora.com/productimages/sku/s2663763-main-zoom.jpg?imwidth=1224",
            "keyingredients": ["Squalane", "Glycerin", "Antarcticine (Glycoprotein)", "Imperata Cylindrica Root Extract", "Avocado Oil"],
            "price": "24.00",
            "fitscore": "85",
            "affiliate_urls": [
              {"store": "Kiehl's", "price": "24.00", "points": 90, "url": "https://sephora.com/product3"},
              { "store": "Sephora", "price": "24.00", "points": 60, "url": "https://sephora.com/product3" },
              { "store": "Ulta", "price": "24.00", "points": 40, "url": "https://ulta.com/product3" },
            ],
            "tags": ["anti-aging", "luxury", "skincare"],
            "description": "A premium anti-aging moisturizer for mature skin.",
            "reviews": [
              {
                "id": 3,
                "name": "Alice Smith",
                "review": "Amazing anti-aging benefits, worth the price."
              }
            ]
          }
        ]
      }
    }
  };

  export default data;