import React, { useState } from 'react';
import { Box, Typography, Button, TextField, Rating, Card, CardContent, Container } from '@mui/material';

const ReviewPage = () => {
  // State for the rating
  const [ratingValue, setRatingValue] = useState(0); // Default to 0, meaning no rating selected yet.

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      {/* Page Title */}
      <Typography variant="h4" textAlign="center" fontWeight="bold" sx={{ mb: 4 }}>
        Share Your Experience
      </Typography>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: '#f4efe8',
          borderRadius: 2,
          p: 4,
        }}
      >
        

        {/* Star Rating */}
        <Rating
          name="product-rating"
          value={ratingValue}
          precision={0.5} // Allows half-star ratings
          onChange={(event, newValue) => setRatingValue(newValue)} // Update the state on change
          sx={{ fontSize: '2rem', mb: 2, color: '#ffb400' }} // Gold color for filled stars
        />

        {/* Review Form */}
        <Box sx={{ width: '100%', maxWidth: '600px' }}>
          <TextField
            label="What did you like about this product?"
            multiline
            rows={4}
            fullWidth
            variant="outlined"
            sx={{ mb: 2 }}
          />
          <TextField
            label="Did it meet your skincare goals?"
            multiline
            rows={4}
            fullWidth
            variant="outlined"
            sx={{ mb: 2 }}
          />

          {/* Submit Button */}
          <Button
            variant="contained"
            color="success"
            fullWidth
            sx={{ mb: 2, fontWeight: 'bold' }}
          >
            Submit Review
          </Button>

          {/* Points Earned */}
          <Box
            sx={{
              backgroundColor: '#e0f7fa',
              borderRadius: 1,
              textAlign: 'center',
              p: 2,
              mb: 2,
            }}
          >
            <Typography variant="body1" color="primary" fontWeight="bold">
              Earn 50 points for sharing your feedback!
            </Typography>
          </Box>

          {/* Additional Buttons */}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            <Button variant="outlined" color="primary" sx={{ width: '48%' }}>
              View My Routine
            </Button>
            <Button variant="outlined" color="primary" sx={{ width: '48%' }}>
              Redeem Points
            </Button>
          </Box>

          <Button
            variant="outlined"
            color="primary"
            fullWidth
            sx={{ mt: 2 }}
          >
            Community Reviews
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default ReviewPage;
