import './App.css';
import { useEffect } from 'react';
import { useLocation, BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Page1 from "./Page1.js";
import Page2 from "./Page2.js";
import Page3 from "./Page3.js";
import Page4 from "./Page4.js";
import Page5 from "./Page5.js";
import Page6 from "./Page6.js";
import Page7 from "./Page7.js";
import Page1N from "./Page1N.js";
import Page2N from "./Page2N.js";
import Page3N from "./Page3N.js";
import PageReview from "./PageReview.js";
import ProductDetails from './ProductDetails.js';

// Google Analytics Initialization
(function () {
  window.dataLayer = window.dataLayer || []; // Ensure dataLayer is defined globally

  const script = document.createElement('script');
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtag/js?id=GTM-NVNQGWWS`;
  document.head.appendChild(script);

  script.onload = () => {
    function gtag() {
      window.dataLayer.push(arguments);
    }
    window.gtag = gtag; // Attach gtag to the global window object
    gtag('js', new Date());
    gtag('config', 'GTM-NVNQGWWS'); // Replace with your Measurement ID
  };
})();

// Hook to track page views for SPAs
function useGoogleAnalytics() {
  const location = useLocation();

  useEffect(() => {
    if (window.gtag) {
      window.gtag('config', 'GTM-NVNQGWWS', {
        page_path: location.pathname,
      });
    }
  }, [location]);
}

function App() {
  return (
    <Router>
      <GoogleAnalyticsWrapper>
        <Routes>
          <Route path="/" element={<Page1N />} />
          <Route path="/page1" element={<Page1 />} />
          <Route path="/Page2" element={<Page2 />} />
          <Route path="/Page3" element={<Page3 />} />
          <Route path="/Page4" element={<Page4 />} />
          <Route path="/Page5" element={<Page5 />} />
          <Route path="/Page6" element={<Page6 />} />
          <Route path="/Page7" element={<Page7 />} />
          <Route path="/Page1N" element={<Page1N />} />
          <Route path="/Page2N" element={<Page2N />} />
          <Route path="/Page3N" element={<Page3N />} />
          <Route path="/PageReview" element={<PageReview />} />
          <Route path="/product/:productId" element={<ProductDetails />} />
          <Route path="*" element={<div>Page Not Found</div>} />
        </Routes>
      </GoogleAnalyticsWrapper>
    </Router>
  );
}

// Component to wrap Google Analytics hook
function GoogleAnalyticsWrapper({ children }) {
  useGoogleAnalytics();
  return <>{children}</>;
}

export default App;