import React from 'react';
import { Container, AppBar, Toolbar, Typography, Box, Button, Card, CardContent, TextField ,Link} from '@mui/material';
import genericImage from './NIACIDAMINE.jpg';
import { Link as RouterLink } from 'react-router-dom';
import data from './data';  // Use in both Page2N and ProductDetails

// FitCheckPage Component
const Page2N = () => {
  // JSON Data
  const products = data.recommendations.user1.products; // Extract products from JSON

  // Header Section
  const Header = () => (
    <AppBar position="static" color="transparent" elevation={0} sx={{ mb: 4 }}>
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          FitCheck
        </Typography>
        <TextField
          variant="outlined"
          size="small"
          placeholder="Search products..."
          sx={{ marginRight: '20px', width: '300px' }}
        />
        <Typography variant="body1" sx={{ marginRight: '15px' }}>
          506 Points
        </Typography>
        <Button variant="contained" color="success">Sign In</Button>
      </Toolbar>
    </AppBar>
  );

  const Filters = () => (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center', // Center horizontally
        mb: 4, // Add margin at the bottom for spacing
      }}
    >
      <Card sx={{ width: '600x', p: 3 }}> {/* Adjust width as needed */}
        <Typography variant="h4" component="h1" fontWeight="bold" textAlign="center">
          Your Personalized Recommendations
        </Typography>
        <Typography variant="subtitle1" component="p" sx={{ mt: 1, mb: 3 }} textAlign="center">
          Based on your skin profile, we've selected products that specifically target hyperpigmentation 
          while being suitable for normal skin type and within the mid-range budget ($20-$50).
        </Typography>
  
        <Box sx={{ display: 'flex', gap: 1, justifyContent: 'center', mb: 3}}>
          <Button variant="outlined">Normal Skin</Button>
          <Button variant="outlined">Hyperpigmentation</Button>
          <Button variant="outlined">$20-$50 Range</Button>
        </Box>
      </Card>
    </Box>
  );
  

  const ProductCard = ({ product }) => (
    <Card sx={{ width: '300px', m: 2, position: 'relative' }}>
      <RouterLink to={`/product/${product.product_id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
      <Box
        component="img"
        sx={{
          height: 220,
          width: '100%',
          objectFit: 'contain',
        }}
        alt={product.name}
        src={product.image_url || genericImage}
      />
      
      

  
      <CardContent>
        <Typography variant="h6" component="h2" sx={{ fontWeight: 'bold' }}>
          {`${product.brand} ${product.name}`}
        </Typography>
        
        <Button
          sx={{
            backgroundColor: 'teal',
            color: 'white',
            mt: 1
          }}
          >
          {`${product.fitscore}% fitscore`}
        </Button>


        <Typography variant="body2" color="textSecondary" sx = {{mt: 1}}>
          ${product.price}
        </Typography>
        <Typography variant="body2" sx={{ mt: 2 }}>
          {product.description}
        </Typography>
      
            
       


      </CardContent>
      </RouterLink>
    </Card>
  );
  

  // Product List Section
  const ProductList = () => (
    <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
      {products.map((product, index) => (
        <ProductCard key={index} product={product} />
      ))}
    </Box>
  );

  return (
    <Box
      sx={{
        backgroundColor: '#f4efe8', // Beige background
        minHeight: '100vh', // Full-height background
        py: 4, // Vertical padding
      }}
    >
      <Container maxWidth="lg">
        <Header />
        <Filters />
        <ProductList />
      </Container>
    </Box>
  );
};


export default Page2N;
